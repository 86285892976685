import React from 'react';
import { ViewTable, Layout, Button, Filter } from '@/components';
import LibUtils from 'lib/utils';
import LibEnum from 'lib/enum';
import WebApi from '@/web/api';

export default class BookPage extends React.PureComponent {

  private table = React.createRef<ViewTable>()
  private queryParams: any = {}

  findAllBook = () => {
    this.table.current?.reset(this.queryParams)
  }

  render() {
    return <Layout.Page>
      <Layout.Toolbar title="费用支出单">
        <Button.Refresh onClick={() => {
          this.table.current?.refresh();
        }}/>
      </Layout.Toolbar>

      <Filter
        fields={[
          {
            type: 'select',
            field: 'tradeType',
            name: '交易类型',
            options: LibUtils.enumToOptions(LibEnum.BOOK_TYPE)
          },
          {
            type: 'input',
            field: 'targetName',
            name: '搜索',
            placeholder: '往来单位'
          },
        ]}
        onChange={(values: any) => {
          this.queryParams.bookTypeList = values?.tradeType ? [values?.tradeType] : undefined as any;
          this.queryParams.targetName = values.targetName;
          this.findAllBook();
        }}
      />
      
      <ViewTable
        flex1
        rowKey={row => row.bookId}
        ref={this.table}
        fetch={data => {
          return WebApi.book_paging({...data, ...this.queryParams})
        }}
        columns={[
          {
            title: '交易编号',
            align: 'center',
            width: 200,
            dataIndex: 'bookId',
          },
          {
            title: '往来单位',
            width: 200,
            dataIndex: 'targetName',
          },
          {
            title: '交易金额',
            align: 'right',
            width: 150,
            dataIndex: 'actualAmount',
            render: (value, row) => {
              if (['12', '13', '16'].includes(row.tradeType)) {
                return <span style={{color: 'green'}}>+{value}</span>
              }
              return <span style={{color: 'red'}}>-{value}</span>
            },
          },
          {
            title: '交易类型',
            align: 'center',
            width: 150,
            dataIndex: 'bookTypeDesc',
          },
          // {
          //   title: '摘要说明',
          //   align: 'center',
          //   width: 100,
          //   dataIndex: 'abstractDesc',
          // },
          {
            title: '交易时间',
            align: 'center',
            width: 200,
            dataIndex: 'datetime',
          },
          {
            title: '创建时间',
            align: 'center',
            width: 200,
            dataIndex: 'createdAt',
          },
          {
            title: '备注说明',
            width: 300,
            dataIndex: 'remark',
            render: value => value || '无',
          },
        ]}
      />
    </Layout.Page>
  }
}